<template>
 <div class="container">
    <h3>Criar Jogo</h3>
    <Form @submit="createGame" class="row g-3">
      <div class="col-md-12">
        <label for="name" class="form-label">Nome</label>
        <input type="text" class="form-control" id="name" v-model="form.name">
      </div>
      <div class="col-md-4">
        <label for="inputPlayers" class="form-label">N. Jogadores</label>
        <select id="inputPlayers" class="form-select" v-model="form.players">
          <option>2</option>
          <option>3</option>
          <option selected>4</option>
          <option>5</option>
          <option>6</option>
        </select>
      </div>
      <div class="col-md-4">
        <label for="inputMap" class="form-label">Mapa</label>
        <select id="inputMap" class="form-select" v-model="form.map">
          <option selected>Random All</option>
          <option>Random Official</option>
          <option>Tharsis</option>
          <option>Hellas</option>
          <option>Elysium</option>
          <option>Amazonis</option>
          <option>Arabia Terra</option>
          <option>V.Borealis</option>
          <option>Terra Cimmeria</option>
        </select>
      </div>
      <div class="col-md-12">
        <h4>Pontuação</h4>
      </div>
      <div class="col-12">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="cbPtvisSwitch" v-model="form.ptvis">
          <label class="form-check-label" for="cbPtvisSwitch">Pontos {{form.ptvis ? "visíveis" : "escondidos"}}</label>
        </div>
      </div>
      <div class="col-md-12">
        <h4>Expansões</h4>
      </div>
      <div class="col-md-12">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="cbPrelude" v-model="form.prelude">
          <label class="form-check-label" for="cbPrelude">Prelude</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="cbVenus" v-model="form.venus">
          <label class="form-check-label" for="cbVenus">Venus</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="cbColonies" v-model="form.colonies">
          <label class="form-check-label" for="cbColonies">Colonias</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="cbfanMade" v-model="form.fanMade">
          <label class="form-check-label" for="cbfanMade">Fan made corps</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="cbTurmoil" v-model="form.turmoil">
          <label class="form-check-label" for="cbTurmoil">Turmoil</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="cbTurmoilA" v-model="form.turmoilA">
          <label class="form-check-label" for="cbTurmoilA">Turmoil Agendas</label>
        </div>
        <div class="col-md-2 offset-md-3 form-select-inline">
          <select id="inputAgendas" class="form-select" v-model="form.turmoilAV" v-if="form.turmoilA">
            <option selected>Chairman</option>
            <option>Random</option>
          </select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="cbTrajectory" v-model="form.trajectory">
          <label class="form-check-label" for="cbTrajectory">Trajectory</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="cbMoon" v-model="form.moon">
          <label class="form-check-label" for="cbMoon">Moon</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="cAres" v-model="form.ares">
          <label class="form-check-label" for="cAres">Ares</label>
        </div>
      </div>
      <div class="col-12">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="cbmaxRating" v-model="form.maxRating">
          <label class="form-check-label" for="cbmaxRating">Limitar Rating</label>
        </div>
      </div>
      <div class="col-12" v-show="form.maxRating">
        <div class="row">
          <div class="col-6">
            <label for="customRange1" class="form-label">Min rating - {{form.ratingMinLimit}}</label>
            <input type="range" class="form-range" min="0" max="100" id="customRange1" v-model.number="form.ratingMinLimit">
          </div>
          <div class="col-6">
            <label for="customRange2" class="form-label">Max rating - {{form.ratingMaxLimit}}</label>
            <input type="range" class="form-range" min="0" max="100" id="customRange2" v-model.number="form.ratingMaxLimit">
          </div>
        </div>
        <div v-if="checkRange" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">
          Os valores min e max são inválidos!
        </div>
      </div> 
      <div class="col-12">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="cbAnonSwitch" v-model="form.anon">
          <label class="form-check-label" for="cbAnonSwitch">Anonimo</label>
        </div>
      </div>
      <div class="col-12" v-show="!form.anon">
        <h4>Côr</h4>
        <ColourPicker :colours="colours" :value="form.colour" @update:value="form.colour = $event" />
      </div>
      <div class="col-12">
        <button class="btn btn-primary btn-block" :disabled="loading">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Save</span>
        </button>
      </div>
      
    </Form>
    <div
      v-if="message"
      class="alert"
      :class="successful ? 'alert-success' : 'alert-danger'"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import UserService from "../services/user.service";
import TMService from "../services/tm.service";
import ColourPicker from "./ui/ColourPicker.vue";

export default {
  name: "New",
  components: {
    Form,
    ColourPicker
  },
  data() {
    return {
      loading: false,
      message: "",
      form:{ 
        players: 4, 
        map: "Random All", 
        turmoilAV: "Chairman", 
        ptvis:true, 
        colour: this.$store.state.auth.user.favouriteColour || 'red',
        ratingMinLimit: Math.floor((this.$store.state.auth.user.openskill || 0) + 50),
        ratingMaxLimit: Math.ceil((this.$store.state.auth.user.openskill || 0) + 50)
      },
      colours: ['red','yellow','blue','green','pink','orange','purple','black']
    };
  },
  computed: {
    checkRange(){
      if(!this.form.maxRating) return false;

      let userRating = (this.$store.state.auth.user.openskill || 0) + 50
      return this.form.ratingMaxLimit < userRating || this.form.ratingMinLimit > userRating || this.form.ratingMinLimit >= this.form.ratingMaxLimit ; 
    }
  },
  methods: {
    createGame() {
      
      if(this.checkRange) return;

      let options = {
        "anon": this.form.anon,
        "ptvis": this.form.ptvis,
        "venus": this.form.venus,
        "turmoil": this.form.turmoil,
        "colonies": this.form.colonies,
        "turmoilA": this.form.turmoilA,
        "turmoilAV": this.form.turmoilAV,
        "prelude": this.form.prelude,
        "map": this.form.map,
        "tiles": false,
        "name": this.form.name,
        "numPlayers": this.form.players,
        "league_id": 0,
        "moon": this.form.moon,
        "trajectory": this.form.trajectory,
        "ares": this.form.ares,
        "fanMade": this.form.fanMade
      };

      if(this.form.maxRating)
      {
        options.minRating = this.form.ratingMinLimit;
        options.maxRating = this.form.ratingMaxLimit;
      }

      let player = {user_id: this.$store.state.auth.user.user_id, colour: this.form.colour}

      TMService.createGame(undefined, options, [player]).then(() => {
        this.$router.push("/home");
      }),
      (error) => {
        this.loading = false;
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      };
    }
  },
  mounted() {
    UserService.getUsers().then(
      (response) => {
        this.content = response.data;
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};
</script>